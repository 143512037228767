import { CLOSE, Icon as DeleteIcon, TRASHBIN } from "components";
import {
    Container,
    DeleteButton,
    IconContainer,
    IconWrapper,
    Name
} from "components/packageCart/list/item/Item.styled";

import {
    PackageItem,
    usePackageCartContext
} from "helpers/context/PackageCartContext";
import { getProductTypeImage } from "helpers/util/product";
import { selectOrDeselectItem } from "lib/gtag";
import { useMediaQueryContext } from "helpers/hooks";

import type { FC } from "react";

interface ItemProps {
    item: PackageItem | null;
    index: number;
    expanded?: boolean;
}

const Item: FC<ItemProps> = ({ item, index, expanded }) => {
    const { isDesktopView, isMobileView } = useMediaQueryContext();
    const { removeProductFromPackage } = usePackageCartContext();

    function removeProductFromDataLayer() {
        if (item && index && window) {
            const productData = {
                brand: "Mr. Fillet",
                category: "Product",
                id: item?.sku,
                name: item.name,
                price: item.price,
                quantity: 1,
                variant: ""
            };
            selectOrDeselectItem("deselect", productData);
        }
    }
    return (
        <Container
            key={`Package-item-${index}`}
            filled={!!item && !!item.id}
            expanded={expanded}
            initial={{
                opacity: 0
            }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
        >
            {item?.category && (
                <IconContainer>
                    <IconWrapper>
                        {getProductTypeImage(
                            item?.category,
                            isDesktopView ? 44 : 33,
                            isDesktopView ? 44 : 33
                        )}
                    </IconWrapper>
                </IconContainer>
            )}
            {((expanded && isMobileView) || !isMobileView) && (
                <Name expanded={expanded}>{item?.name}</Name>
            )}
            {item?.uid && (
                <DeleteButton
                    onClick={() => {
                        removeProductFromPackage(index, item?.uid);
                        removeProductFromDataLayer();
                    }}
                    expanded={expanded}
                >
                    {expanded ? (
                        <DeleteIcon
                            icon={TRASHBIN}
                            size={24}
                            viewbox="0 0 24 24"
                        />
                    ) : (
                        <DeleteIcon
                            icon={CLOSE}
                            size={isDesktopView ? 16 : 12}
                            viewbox="0 0 14 7"
                            stroke="var(--color-beige-40)"
                            fill="none"
                        />
                    )}
                </DeleteButton>
            )}
        </Container>
    );
};
export default Item;
