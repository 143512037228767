import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";

function ClientPortal({
    children,
    selector
}: {
    children: JSX.Element;
    selector: string;
}): JSX.Element | null {
    const ref = useRef<Element | null>(null);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        ref.current = document.querySelector(selector);

        setMounted(true);

        return () => {
            setMounted(false);
        };
    }, [selector]);

    return mounted && !!ref.current
        ? createPortal(children, ref.current)
        : null;
}

export default ClientPortal;
