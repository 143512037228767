import Image from "next/image";
import MixMatchImage from "public/images/mix-match-visual.png";
import dynamic from "next/dynamic";

import {
    ARROW_RIGHT,
    Button,
    CLOSE_WITH_TEXT,
    INFO,
    Icon,
    Modal
} from "components";
import {
    CloseButton,
    Content,
    Description,
    ImageContainer,
    ImageWrapper,
    Info,
    InfoIcon,
    InfoTitle,
    Title,
    Usp,
    UspContent,
    UspIcon,
    Usps,
    Wrapper
} from "components/packageCart/infoModal/InfoModal.styled";

import { useMediaQueryContext } from "helpers/hooks";

const DottedPattern = dynamic<{
    fill: string;
    height: string;
    preserveAspectRatio: string;
    width: string;
}>(() => import("components/shared/svg").then(mod => mod.DottedPattern));

interface InfoModal {
    showModal: boolean;
    onClose: () => void;
    contentLabel?: string;
}

function InfoModal({
    showModal,
    onClose,
    contentLabel = "Packagecart Information Modal"
}: InfoModal): JSX.Element {
    const { isMobileView, isDesktopView } = useMediaQueryContext();
    return (
        <Modal
            showModal={showModal}
            onClose={() => onClose()}
            isOpen={showModal}
            contentLabel={contentLabel}
            minWidth={288}
            maxWidth={725}
            mobilePadding="0"
            desktopPadding="0"
        >
            <CloseButton onClick={() => onClose()} isMobileView={isMobileView}>
                <Icon
                    icon={CLOSE_WITH_TEXT}
                    color="var(--color-secondary)"
                    size={32}
                />
            </CloseButton>
            <Wrapper>
                <ImageContainer>
                    <ImageWrapper>
                        <DottedPattern
                            fill="var(--color-beige-50)"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMidYMid slice"
                        />
                    </ImageWrapper>
                    <Image
                        src={MixMatchImage}
                        alt="mix en match afbeelding"
                        width={289}
                        height={268}
                    />
                </ImageContainer>
                <Content>
                    <Title>Mix &amp; Match</Title>
                    <Description>
                        {isDesktopView
                            ? "Bestellen moet altijd per 4 producten."
                            : "Bestellen per 4 producten"}
                    </Description>
                    <Usps>
                        <Usp>
                            <UspIcon icon={ARROW_RIGHT} />
                            <UspContent>
                                Mix &amp; Match: Jij kiest welke 4 producten je
                                wilt
                            </UspContent>
                        </Usp>
                        <Usp>
                            <UspIcon icon={ARROW_RIGHT} />
                            <UspContent>
                                Voeg het aantal artikelen toe aan je pakket en
                                plaats het pakket in je winkelmandje.
                            </UspContent>
                        </Usp>
                    </Usps>
                    <Info>
                        <InfoTitle>
                            <InfoIcon icon={INFO} />
                            Waarom bestellen per 4 producten?
                        </InfoTitle>
                        <p>
                            Onze dozen zijn gemaakt voor precies 4 pakjes met
                            kakelverse kip. Samen met de koelelementen en gratis
                            diepvrieszakjes past dit perfect. De prijs per
                            product hangt af van het gewicht per pakje.
                        </p>
                        <Button
                            size="simple"
                            buttonType="link"
                            icon={{ icon: ARROW_RIGHT }}
                            reversedOrder
                            href="/faq"
                        >
                            Meer informatie
                        </Button>
                    </Info>
                </Content>
            </Wrapper>
        </Modal>
    );
}

export default InfoModal;
