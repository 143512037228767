import { AnimationProps, motion } from "framer-motion";
import styled, { css } from "styled-components";

import { media } from "constants/media";

interface ItemAnimationProps extends AnimationProps {
    filled?: boolean;
    expanded?: boolean;
}

export const Container = styled(motion.li)<ItemAnimationProps>`
    align-items: center;
    background-color: ${({ filled }) =>
        filled ? "var(--color-beige-40)" : "initial"};
    border: ${({ filled }) =>
        filled
            ? "1px solid var(--color-beige-40)"
            : "1px dashed var(--color-beige-40) "};
    color: ${({ filled }) =>
        filled ? "var(--color-brown-40)" : "var(--color-beige-40)"};
    display: ${({ expanded }) => (expanded ? "flex" : "inline-flex")};
    flex: 0 1 auto;
    height: 40px;
    justify-content: center;
    margin: ${({ expanded }) => (expanded ? "0 2px 10px" : "0 2px")};
    max-width: ${({ expanded }) => (expanded ? "100%" : "52px")};
    min-width: 52px;

    padding: 10px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    ${media.mdUp} {
        height: 56px;
        margin: 0 2px;
        max-width: 158px;
        padding: 5px;
        width: 100%;
    }
`;

export const IconContainer = styled.div`
    margin-right: auto;
`;

export const IconWrapper = styled.div`
    flex-basis: 33px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 33px;
    padding: 5px;
    position: relative;
    width: 33px;

    ${media.mdUp} {
        flex-basis: 44px;
        height: 44px;
        width: 44px;
    }
`;

export const DeleteButton = styled.button<ItemAnimationProps>`
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 0;

    /* stylelint-disable order/properties-alphabetical-order */
    ${({ expanded }) =>
        !expanded &&
        css`
            align-items: center;
            background-color: var(--color-red-50);
            border-radius: 50%;
            height: 16px;
            padding: 0;
            position: absolute;
            right: 4px;
            top: -9px;
            width: 16px;

            ${media.mdUp} {
                cursor: pointer;
                height: 24px;
                right: 12px;
                top: -12px;
                width: 24px;
            }
        `}; /* stylelint-enable order/properties-alphabetical-order */
`;

export const Name = styled.div<ItemAnimationProps>`
    flex: 1 1 92px;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    /* stylelint-disable order/properties-alphabetical-order */
    ${({ expanded }) =>
        expanded &&
        css`
            margin-left: 0;
            text-align: center;
        `}; /* stylelint-enable order/properties-alphabetical-order */
`;
