import styled from "styled-components";

export const List = styled.ul<{ filled?: boolean; expanded?: boolean }>`
    align-items: center;
    display: inline-flex;
    flex-direction: ${({ expanded }) => (expanded ? "column" : "row")};
    justify-content: flex-start;
    list-style: none;
    position: relative;
    width: 100%;
`;
