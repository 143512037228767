import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { Button } from "components";

import { media } from "constants/media";

export const Container = styled(motion.div)`
    background-color: var(--color-brown-50);
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 20;
`;
export const Wrapper = styled.div`
    color: var(--color-beige-40);
    font-size: 14px;
    line-height: 24px;
    margin: 0 auto;
    padding: 8px 24px;
    width: 100%;

    ${media.mdUp} {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        line-height: 24px;
        max-width: 1200px;
        padding: 20px 24px;
    }

    ${media.lgUp} {
        flex-wrap: nowrap;
    }
`;

export const Top = styled.div<{ expanded?: boolean }>`
    align-items: ${({ expanded }) => (expanded ? "flex-start" : "center")};
    display: inline-flex;
    justify-content: center;
    width: 100%;

    ${media.lgUp} {
        flex: 1;
        max-width: 100%;
        width: auto;
    }
`;

export const ToggleContainer = styled.div`
    margin-right: 19px;
`;

export const Toggle = styled.button`
    background: none;
    border: 0;
    outline: none;
`;

export const Bottom = styled.div`
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;

    ${media.lgUp} {
        flex: 1;
        margin-top: 0;
        max-width: 100%;
        width: auto;
    }
`;

export const Totals = styled(motion.div)`
    align-items: center;
    display: inline-flex;

    ${media.lgUp} {
        margin-left: 57px;
    }
`;

export const AddToCartButton = styled(Button)<{ isSmall?: boolean }>`
    ${({ isSmall }) =>
        isSmall &&
        css`
            align-items: center;
            height: 48px;
            justify-content: center;
            padding: 0;
            width: 48px;
        `}
`;

export const InfoButton = styled.button`
    background: none;
    border: 0;
    color: var(--color-beige-40);
    cursor: pointer;
    font-size: 26px;
    line-height: 24px;
    margin: 0;
    outline: none;
    padding: 0 0 0 5px;
`;
