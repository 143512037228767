import { Fragment, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import dynamic from "next/dynamic";

import {
    AddToCartButton,
    Bottom,
    Container,
    InfoButton,
    Toggle,
    ToggleContainer,
    Top,
    Totals,
    Wrapper
} from "components/packageCart/PackageCart.styled";
import { CHEVRON_UP, INFO, Icon, SHOPPING_BASKET_ADD } from "components";
import ClientPortal from "components/richText/clientPortal/ClientPortal";
import InfoModal from "components/packageCart/infoModal/InfoModal";
import List from "components/packageCart/list/Items";

import { cartAddOrRemoveEvent } from "../../lib/gtag";

import { formatPrice } from "helpers/format";
import { useMediaQueryContext } from "helpers/hooks";
import { usePackageCartContext } from "helpers/context/PackageCartContext";
import { useSuccessModal } from "helpers/context/SuccessModalContext";

const Progress = dynamic(
    () => import("components/shared/progressCircle/ProgressCircle")
);

function PackageCart(): JSX.Element | null {
    const containerRef = useRef(null);
    const { setShow } = useSuccessModal();
    const { isMobileView, isXlDesktopView } = useMediaQueryContext();
    const { items, total, addPackageToCart, totalItems } =
        usePackageCartContext();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    useEffect(() => {
        if (expanded && !isMobileView) {
            setExpanded(false);
        }
    }, [expanded, isMobileView]);

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.5 } }
    };

    const itemsLessThanFour = totalItems < 4;

    function addPackageToDataLayer() {
        const productsForDataLayer = items
            .filter(item => item?.sku)
            .map(item => ({
                brand: "Mr. Fillet",
                category: item?.category,
                id: item?.sku,
                name: item?.name,
                price: item?.price,
                quantity: item?.quantity,
                variant: "1.5kg"
            }));
        cartAddOrRemoveEvent("add", productsForDataLayer);
    }

    if (totalItems === 0) {
        return null;
    }

    return (
        <ClientPortal selector="#package-cart">
            <Fragment>
                <Container
                    ref={containerRef}
                    initial={{
                        opacity: 0,
                        y: isMobileView ? 68 : 100
                    }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.25 }}
                >
                    <Wrapper>
                        <Top expanded={expanded}>
                            {isMobileView && (
                                <ToggleContainer>
                                    <Toggle
                                        onClick={() => setExpanded(!expanded)}
                                        aria-expanded={expanded}
                                        aria-label="package cart mobile button"
                                    >
                                        <motion.div
                                            initial={{ origin: 0 }}
                                            animate={{
                                                origin: 0,
                                                rotateX: expanded ? 180 : 0
                                            }}
                                            transition={{
                                                duration: 0.2
                                            }}
                                        >
                                            <Icon
                                                icon={CHEVRON_UP}
                                                size={20}
                                                fill="var(--color-beige-40)"
                                            />
                                        </motion.div>
                                    </Toggle>
                                </ToggleContainer>
                            )}
                            <List items={items} expanded={expanded} />
                        </Top>
                        <Bottom>
                            <Totals
                                variants={fadeIn}
                                initial="hidden"
                                animate="visible"
                            >
                                {itemsLessThanFour ? (
                                    <>
                                        Kies eerst 4 producten{" "}
                                        <InfoButton
                                            aria-label="package-info-button"
                                            onClick={() =>
                                                setShowInfoModal(!showInfoModal)
                                            }
                                        >
                                            <Icon icon={INFO} />
                                        </InfoButton>
                                    </>
                                ) : (
                                    `Pakketprijs: ${formatPrice(total)}`
                                )}
                            </Totals>
                            {itemsLessThanFour && (
                                <motion.div
                                    variants={fadeIn}
                                    initial="hidden"
                                    animate={
                                        itemsLessThanFour ? "visible" : "hidden"
                                    }
                                >
                                    <Progress count={totalItems} maxCount={4} />
                                </motion.div>
                            )}
                            {!itemsLessThanFour && (
                                <motion.div
                                    variants={fadeIn}
                                    initial="hidden"
                                    animate={
                                        !itemsLessThanFour
                                            ? "visible"
                                            : "hidden"
                                    }
                                >
                                    <AddToCartButton
                                        size="large"
                                        buttonType="primary"
                                        icon={{
                                            icon: SHOPPING_BASKET_ADD,
                                            size: 32,
                                            viewbox: "0 0 35 30"
                                        }}
                                        onClick={() => {
                                            setLoading(true);
                                            addPackageToCart();
                                            setLoading(false);
                                            setShow(true);
                                            addPackageToDataLayer();
                                        }}
                                        reversedOrder
                                        disabled={loading || itemsLessThanFour}
                                        isSmall={!isXlDesktopView}
                                    >
                                        {isXlDesktopView
                                            ? "In winkelwagen"
                                            : null}
                                    </AddToCartButton>
                                </motion.div>
                            )}
                        </Bottom>
                    </Wrapper>
                </Container>

                {showInfoModal && (
                    <InfoModal
                        showModal={showInfoModal}
                        onClose={() => setShowInfoModal(!showInfoModal)}
                    />
                )}
            </Fragment>
        </ClientPortal>
    );
}

export default PackageCart;
