import styled from "styled-components";

import { Icon } from "components";

import { media } from "constants/media";

export const Content = styled.div`
    flex: 1;
    padding: 16px 16px 0;
`;

export const Title = styled.div`
    color: var(--color-orange-50);
    font-family: var(--font-tertiary);
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;

    ${media.xlUp} {
        margin-bottom: 24px;
    }
`;

export const Description = styled.p`
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

    ${media.xlUp} {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 24px;
    }
`;

export const Usps = styled.ul`
    list-style: none;
    margin: 0 0 8px;
    padding: 0;

    ${media.lgUp} {
        margin-bottom: 0;
    }
`;

export const Usp = styled.li`
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;

    ${media.lgUp} {
        font-size: 18px;
        line-height: 32px;
    }
`;

export const UspContent = styled.div`
    flex: 1 1 80%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
`;

export const UspIcon = styled(Icon)`
    flex: 1 1 10%;
    margin-right: 10px;
    width: 22px;
`;

export const Info = styled.div`
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
`;

export const InfoTitle = styled.div`
    align-items: center;
    display: flex;
    font-weight: 700;
    margin-bottom: 8px;
`;

export const InfoIcon = styled(Icon)`
    margin-right: 10px;
`;

export const ImageContainer = styled.div`
    align-items: center;
    background-color: var(--color-orange-50);
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    padding: 24px;
    position: relative;
    width: 100%;
`;

export const ImageWrapper = styled.div`
    bottom: -14%;
    left: -217%;
    position: absolute;
    right: -149%;
    top: -59%;

    ${media.mdUp} {
        top: -89%;
    }

    ${media.xlUp} {
        bottom: -50%;
        left: -50%;
        right: -50%;
        top: -50%;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    width: 100%;
    z-index: 1;

    ${media.xlUp} {
        flex-direction: row-reverse;
        padding-bottom: 24px;
    }
`;

export const CloseButton = styled.button<{ isMobileView?: boolean }>`
    align-items: center;
    background-color: var(--color-white);
    border: 0;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    outline: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    z-index: 30;

    ${media.xlUp} {
        height: 80px;
        width: 80px;
    }
`;
