import { List } from "components/packageCart/list/Items.styled";
import Item from "components/packageCart/list/item/Item";

import { PackageItem } from "helpers/context/PackageCartContext";

interface Props {
    items: Array<PackageItem | null>;
    expanded?: boolean;
}

function Items({ items, expanded }: Props): JSX.Element {
    return (
        <List expanded={expanded}>
            {items.map((item, index) => {
                return (
                    <Item
                        key={`package-item-${index}`}
                        item={item}
                        index={index}
                        expanded={expanded}
                    />
                );
            })}
        </List>
    );
}

export default Items;
